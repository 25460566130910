import firebase from 'firebase/app'
import { useContext } from 'react'
import "firebase/database"
import "firebase/auth"
import { AuthContext } from './components/AuthProvider';
const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DB,
    projectId: process.env.REACT_APP_PID,
    storageBucket: process.env.REACT_APP_SB,
    messagingSenderId: process.env.REACT_APP_SID,
    appId: process.env.REACT_APP_APPID
};
firebase.initializeApp(config);
const databaseRef = firebase.database().ref();

export const firebaseAppAuth = firebase.auth();
export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

firebase.auth().onAuthStateChanged((user) => {
    const authContext = useContext(AuthContext);
    // if user isn't null then we logged in
    if (user) {
        authContext.setUser(user);
    } else {
        // logout
}
})

export const booksRef = databaseRef.child("books")
export default firebase;