import { auth } from "firebase";
import React, { useState, useRef, useEffect } from "react";
import { booksRef } from '../firebase';


interface customFormOnSubmitArgs<T> {
    values: T
    errors: {}
} 
// interface customFormOnSubmitArgsEmpty {
//     values: {}
//     errors: {}
// } 

function useCustomForm<T>(initialValues: T, onSubmit: ({}: customFormOnSubmitArgs<T>)=>void) {
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState({})
    const [touched, setTouched] = useState({})
    const [onSubmitting, setOnSubmitting] = useState<boolean>(false)
    const [onBlur, setOnBlur] = useState<boolean>(false)

    const formRendered = useRef(true);

    useEffect(() => {
      if (formRendered.current) {
        setValues(initialValues);
        setErrors({});
        setTouched({});
        setOnSubmitting(false);
        setOnBlur(false);
      }
      formRendered.current = false;
    }, [initialValues])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { name, value } = target;
        event.persist();
        setValues({ ...values, [name]: value });
    }
    const handleSubmit = (event: any) => {
        if (event) event.preventDefault();
        setErrors({ ...errors });
        onSubmit({ values, errors });
    };

    return {
        values,
        errors,
        touched,
        handleChange,
        // handleBlur,
        handleSubmit
      };
}

interface Book {
    title: string
    author: string
  }


export function NewBookForm() {
    // const [bookAuthor, setBookAuthor] = useState('')
    // const [bookTitle, setBookTitle] = useState('')

    const onSubmitBook = ({values, errors} : customFormOnSubmitArgs<Book>) => {
        console.log(values)
        const newBookRef = booksRef.push()
        newBookRef.set(values)
    }

    const initialValues: Book = {title:'', author: ''}
    
    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit
      } = useCustomForm<Book>(initialValues, onSubmitBook)

    

    // const submitNewBook = () => {
    //     console.log({bookAuthor, bookTitle})
    // }
    
    return (
        <form onSubmit={handleSubmit} className="App">
            <label>
                Author:
                <input type="Text" name="author" value={values.author} onChange={handleChange} />
            </label>
            <label>
                Title:
                <input type="Text" name="title" value={values.title} onChange={handleChange} />
            </label>
            <button type="submit">Submit</button>
        </form>
    )
}
