import React, { useEffect, useState } from 'react'
import { isTemplateExpression } from 'typescript';
import { NewBookForm } from '../components/NewBookForm';
import { booksRef } from '../firebase';

interface Book {
  title: string
  author: string
}

function renderNominee(book: Book) {
  return (
  <li>{book.title} by {book.author}</li>
  )
}

export function PastNominees() {
  // const nominees = [{
  //   title: "To Kill A Mockingbird",
  //   author: "Harper Lee"
  // }, {
  //   title: "East of Eden",
  //   author: "John Steinbeck"
  // }]
  // const nominees = booksRef.orderByChild('title')
  const [books, setBooks] = useState<Book[]>([])

  // booksRef.once('value').then(function(snapshot) {
  //   var booklist = snapshot.val()
  //   console.log(booklist, 'booklist')
  //   // ...
  // });  
  useEffect(() => {
    console.log('useeffect')

    booksRef.on('value', (snapshot) => {
      let items = snapshot.val();
      // let newState = [];
      // for (let item in items) {
      console.log("Items", items)
      const newState = items.map((item: Book) => ({
        author: item.author,
        title: item.title
      }))
      setBooks(newState)
      console.log("Testing")
    });
  }, [])

  const listOfNominees = books.map(renderNominee)
  return (
    <div>
      <h2>Past Nominees</h2>
      <ul>{listOfNominees}</ul>
      <NewBookForm />
    </div>
  );
}
