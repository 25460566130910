import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { User, auth } from "firebase";
import firebase from "../firebase";
import "firebase/auth";
import "firebase/firestore";
import firebaseui from 'firebaseui'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import { AuthContext } from "./AuthProvider";
interface UserData {
    email: string;
    password: string;
}
const Login = () => {
    const history = useHistory();
  
  var uiConfig = {
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
    //   firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    signInSuccessUrl: '/',
  };

  return (
     <div style={{textAlign: 'center'}}>
     <h1>Login</h1>
     <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
     <div id="loader">Loading...</div>
   </div>
  )
}
export default Login;